import React from "react"
import { Box, VStack, HStack, Text, Divider, Tag, TagLabel, TagCloseButton } from "@chakra-ui/react"

import { useCheckoutContext } from "@app/providers/checkout"
import { useCheckout } from "@app/hooks/useCheckout"
import { useShopify } from "@app/hooks/useShopify"

type Props = {
  language: {
    subtotal: string
    discounts: string
  }
}
const CartSummary: React.FC<Props> = ({ language }) => {
  const { checkout } = useCheckoutContext()
  const { removeDiscountCode } = useCheckout()
  const { formatMoney } = useShopify()
  const subTotal = formatMoney(checkout?.lineItemsSubtotalPrice?.amount || 0)
  const appliedDiscountCode = checkout?.discountApplications?.[0]?.code
  const discountApplied = parseFloat(`${checkout?.lineItemsSubtotalPrice?.amount}`) - parseFloat(`${checkout?.subtotalPriceV2?.amount}`)
  const formattedDiscountApplied = formatMoney(
    parseFloat(`${checkout?.lineItemsSubtotalPrice?.amount}`) - parseFloat(`${checkout?.subtotalPriceV2?.amount}`)
  )

  return (
    <Box textAlign="left" mb="4">
      <VStack width="full" align="stretch">
        <Divider />
        <HStack justify="space-between" pt="2">
          <Text size="mediumParagraph">{language.subtotal}</Text>
          <Text size="smSemiSmall">{subTotal}</Text>
        </HStack>
        {discountApplied > 0 ? (
          <HStack justify="space-between">
            <Text as={"div"}>
              {language.discounts}&nbsp;
              <Tag size="md" borderRadius="full" variant="solid">
                <TagLabel>{appliedDiscountCode}</TagLabel>
                <TagCloseButton onClick={() => removeDiscountCode()} />
              </Tag>
            </Text>
            <Text>-&nbsp;{formattedDiscountApplied}</Text>
          </HStack>
        ) : null}
      </VStack>
    </Box>
  )
}

export default React.memo(CartSummary)
