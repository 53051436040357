import React from "react"
import { HStack, Text, Skeleton } from "@chakra-ui/react"
import { useAppContext } from "@app/providers/app"
import { useShopifyPrice } from "@app/hooks/useShopify"

type Props = {
  variant?: any
  loading?: boolean
}

const ProductPrice: React.FC<Props> = ({ variant, loading = false }) => {
  const { activeVariant } = useAppContext()
  const { formattedPrice, formattedCompareAtPrice, onSale } = useShopifyPrice(variant || activeVariant)

  return (
    <Skeleton width={16} isLoaded={!loading && formattedPrice !== undefined} mb="2">
      <HStack>
        <Text size="smallParagraph">{formattedPrice}&nbsp;</Text>
        {onSale ? (
          <>
            <Text size="smallParagraph">{formattedCompareAtPrice}</Text>
            &nbsp;
          </>
        ) : null}
      </HStack>
    </Skeleton>
  )
}

export default React.memo(ProductPrice)
